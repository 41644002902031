import React,{ useEffect,useState } from "react";
import '../App.css';
import { Button } from 'primereact/button';
import './HeroSection.css';
import { View, Image, StyleSheet } from 'react-native';
import { SocialIcon } from 'react-social-icons';

function HeroSection() {
  const [info,setInfo] = useState(0);
  const [exp,setExp] = useState(0);
  const [contact,setContact] = useState(0);
  const [social,setSocial] = useState(0);
  const [name,setName] = useState("#TEAM_DEAD");


 
  const handleInfo = ()=> {
    setInfo(!info)
    setExp(0)
    setContact(0)
    if(name == "#TEAM_DEAD"){
      setName("#TEAM_ALIVE")
    }
    if(name == "#TEAM_ALIVE"){
      setName("#TEAM_DEAD")
    }
  }
  const handleExp = ()=> {
    setExp(!exp)
    setInfo(0)
    setContact(0)
  }
  const handleArt = ()=> {
    setContact(!contact)
    setInfo(0)
    setExp(0)
  }
  const handleSocial = ()=> {
    setSocial(!social)
  }
  const handleRedirectTwitter = ()=> {
    setInfo(0)
    setExp(0)
    window.open('https://twitter.com/emmySOL22', '_blank');
  }
  const handleRedirectDiscord = ()=> {
    setInfo(0)
    setExp(0)
    window.open('https://t22.io/launch/EMMY', '_blank');
  }
  const styles = StyleSheet.create({
    container: {
      // paddingTop: 50,
      // backgroundColor:'white'
    },
    tinyLogo: {
      width: 50,
      height: 50,
    },
    logo: {
      width: 100,
      height: 50,
      resizeMode: 'contain',
    },
    logobig: {
      width: 200,
      height: 200,
      resizeMode: 'contain',
    },
    contact:{
      width: 700,
      height: 250,
      resizeMode: 'contain',
    },
    exp:{
      width: 700,
      height: 250,
      resizeMode: 'contain',
    },
    logotomabout:{
      width: 500,
      height: 350,
      resizeMode: 'contain',
    },
    logojerryabout:{
      width: 500,
      height: 210,
      resizeMode: 'contain',
    }

  });
  return (
    <div className={`hero-container ${info ? 'morning' : 'evening'}`}>
      <div className="navbar">
        
        <ul className='nav-menu'>
            {/*<li className="nav-links" >
              <Button className="nav-buttons" onClick={handleInfo}>{name}</Button>
            </li>
*/}            <li className="nav-links" >
              <Button className="nav-buttons" onClick={handleRedirectDiscord}>Mint is Live</Button>
            </li>
            <li className="nav-links" >
              <Button className="nav-buttons" onClick={handleRedirectTwitter}>Twitter</Button>
            </li>

          </ul>
          </div>
          
          
    <div className="social">
      <div className="nav-links">
        <Button className="nav-buttons" onClick={handleSocial}>COMING SOON</Button>
      </div>
      {/* {social ? 
    <div>
 */}
    </div>
    </div>
  );
  
}



export default HeroSection;
